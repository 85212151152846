import React from "react";
import { compact, cloneDeep } from "lodash";
import cx from "classnames";
import dayjs from "dayjs";
import { useHistory } from "react-router";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
// eslint-disable-next-line no-restricted-imports
// eslint-disable-next-line no-restricted-imports
import { DoctorAccordion } from "../AppointmentSelectSlot/DoctorAccordion";
// eslint-disable-next-line no-restricted-imports
import { toTel } from "../utils/toTel";
import styles from "./MobileView.module.scss";
import { NearbyOffice } from "./NearbyOffice";
import { AppointmentExamDetails } from "./AppointmentExamDetails";
import { AppointmentSelectReturnStatus } from "./AppointmentSelectReturnStatus";
import { AppointmentAccountSetup } from "./AppointmentAccountSetup";
import { useTrackEvent } from "hooks/useTrackEvent";
import { Modal } from "components/Modal";
import circleCheck from "images/circle-check.svg";
import { API_DATE_FORMAT } from "api/constants";
import { Button, ButtonRole } from "components/Button";
import { InfoTooltip } from "components/InfoTooltip";
import { WaitlistV2 } from "features/scheduling/components/Waitlist/WaitlistV2";
import {
  DoctorAvailability,
  selectDoctorIdsOrderedByAvailability,
  selectNextAvailableDatesForOffice,
} from "features/scheduling/availabilitySummarySlice";
import {
  selectAppointmentData,
  selectIsEditForm,
  selectEditSection,
} from "features/appointmentDataSlice";
import * as paths from "features/routing/paths";
import { NavigationButton } from "features/scheduling/AppointmentSelectSlot/NavigationButton";
import { ConnectedDoctorSlots } from "features/scheduling/components/ConnectedDoctorSlots";
import { FooterPortal } from "components/Footer/Footer";
//import { DateInput, Hook } from "components/DateInput";
import { Doctor } from "types/doctor";
import { ExamType } from "types/examType";
import { Office } from "types/office";
import { PatientReturnStatus } from "types/patientReturnStatus";
import { MYEYEDR_WEBSITE_URL } from "utils/constants";
import { externalRedirect } from "utils/externalRedirect";
import { RootState } from "app/store";
import { selectNearbyOfficeLoadingState } from "features/scheduling/nearbyOfficesSlice";
import { updateSelectedAppointment } from "features/scheduling/appointmentCreationSlice";
import { Slot } from "types/slot";
import {
  selectUpcomingTimeslotsForOfficeByDate,
  selectIsLoaded,
} from "features/scheduling/timeSlotsSlice";
import successCircle from "images/success-circle-ticker.svg";
import errorCircle from "images/error-circle-cross.svg";
//import { useSchedulerTracking } from "hooks/useSchedulerTracking";

const aria = {
  labelledby: "appointment-exam-details-modal-label",
  describedby: "appointment-exam-details-modal-description",
};

interface MobileViewProps {
  doctors: Doctor[];
  officeId: string;
  examType: ExamType;
  nearbyOffices: Office[];
  patientReturnStatus: PatientReturnStatus;
  selectedOffice: Office;
  searchDate: string;
  setSearchDate: (newVal: string) => void;
  setTimeslotsExecution: () => void;
  setApptData: (data: any) => void;
  patientDetailsValid: any;
}
declare let orderContactUrl: string;
export const MobileView: React.FC<MobileViewProps> = ({
  doctors,
  officeId,
  examType,
  nearbyOffices,
  patientReturnStatus,
  selectedOffice,
  searchDate,
  setSearchDate,
  setTimeslotsExecution,
  setApptData,
  patientDetailsValid,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { trackEvent } = useTrackEvent();
  const isMobileView = true;
  const examTypeRef = React.useRef<null | HTMLDivElement>(null);
  const patientTypeRef = React.useRef<null | HTMLDivElement>(null);
  const patientDetailsRef = React.useRef<null | HTMLDivElement>(null);
  const [prevWeek, setPrevWeek] = React.useState<string>("");
  const [nextWeek, setNextWeek] = React.useState<string>("");
  const [selectedDate, setSelectedDate] = React.useState<undefined | string>(
    undefined
  );
  const { formattedPhone, officeUid } = selectedOffice;
  const [eyeExam, setEyeExam] = React.useState<undefined | ExamType>(undefined);
  const [selectedDoctor, setSelectedDoctor] = React.useState<
    undefined | Doctor
  >(undefined);
  const [selectedSlot, setSelectedSlot] = React.useState<undefined | Slot>(
    undefined
  );
  const dummyPatientDetails = {
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    dateOfBirth: "",
  };
  const [patientDetails, setPatientDetails] =
    React.useState<any>(dummyPatientDetails);
  const [patientType, setPatientType] = React.useState<
    undefined | PatientReturnStatus
  >(undefined);
  const [nextButton, setNextButton] = React.useState(true);
  const [executeOnce, setExecuteOnce] = React.useState(false);
  const [dateFirstexecuteOnce, setDateFirstExecuteOnce] = React.useState(false);
  const [dataPush, setDataPush] = React.useState(false);
  const [showSlotSection, setShowSlotSection] = React.useState(false);
  const appoinmentData = useSelector(selectAppointmentData);
  const isEditForm = useSelector(selectIsEditForm);
  const editSection = useSelector(selectEditSection);
  const selectedDateTemp =
    selectedDate === undefined ? searchDate : selectedDate;
  const [vehSelectedSlot, setVehSelectedSlot] = React.useState<
    undefined | Slot
  >(undefined);
  const [vehDoctorTerms, setVehDoctorTerms] = React.useState<boolean>(false);

  const doctorIdsByAvailability = useSelector(
    (state: RootState) =>
      selectDoctorIdsOrderedByAvailability(
        state,
        officeId,
        selectedDateTemp,
        selectedOffice.timeSlotWeeks
      ),
    shallowEqual
  );
  const doctorAvailbilities = useSelector(
    (state: RootState) =>
      selectNextAvailableDatesForOffice(
        state,
        officeId,
        selectedDateTemp,
        selectedOffice.timeSlotWeeks
      ),
    shallowEqual
  );
  //const { trackSchedulerEvent } = useSchedulerTracking();
  /*const onDateChange: Hook = (_selectedDates, dateStr) => {
    trackSchedulerEvent("browse-dates");
    setSearchDate(dateStr);
  };*/

  const onSelectionTimeSlot = (type: string, value: Slot) => {
    let doctorCache: Doctor | undefined;
    doctors.map((doctor) => {
      if (value.doctorId === doctor.id) {
        doctorCache = doctor;
        setSelectedDoctor(doctor);
      }
    });
    if (doctorCache?.offersVEH) {
      setVehDoctorTerms(true);
      setVehSelectedSlot(value);
    } else {
      setSelectedSlot(value);
      dispatch(
        updateSelectedAppointment({
          officeId,
          officeUid,
          ...value,
        })
      );
    }
    //isFormValidation(patientDetails);
    isFormValidation({
      eyeExam: eyeExam,
      patientType: patientType,
      selectedDoctor: selectedDoctor,
      selectedSlot: selectedSlot,
      patientDetails: patientDetails,
    });
    setDataPush(false);
    trackEvent("step-2-scheduler-start", {
      location: officeId,
    });
    trackEvent("step-5-selected-appointment-time", {
      location: officeId,
      selected_type: eyeExam,
      patient_type: patientType,
      doctorId: doctorCache?.id,
    });
  };

  const onNextMobile = () => {
    if (selectedDoctor?.offersVEH) {
      setSelectedSlot(vehSelectedSlot);
    }
    setShowSlotSection(true);
    setTimeout(() => {
      examTypeRef?.current?.scrollIntoView();
    }, 10);
    //isFormValidation(patientDetails);
    isFormValidation({
      eyeExam: eyeExam,
      patientType: patientType,
      selectedDoctor: selectedDoctor,
      selectedSlot: selectedSlot,
      patientDetails: patientDetails,
    });
    setDataPush(false);
  };

  const onSelectionExamType = (type: string, value: ExamType) => {
    setEyeExam(value);
    /* some delay when popup close */
    setTimeout(() => {
      patientTypeRef?.current?.scrollIntoView();
      trackEvent("step-3-selected-eyewear-type", {
        location: officeId,
        selected_type: value,
        patient_type: patientType,
        doctorId: selectedDoctor?.id,
      });
    }, 10);
    //isFormValidation(patientDetails);
    isFormValidation({
      eyeExam: value,
      patientType: patientType,
      selectedDoctor: selectedDoctor,
      selectedSlot: selectedSlot,
      patientDetails: patientDetails,
    });
    setDataPush(false);
  };

  const onSelectionPatientType = (type: string, value: PatientReturnStatus) => {
    setPatientType(value);
    patientDetailsRef?.current?.scrollIntoView();
    isFormValidation({
      eyeExam: eyeExam,
      patientType: value,
      selectedDoctor: selectedDoctor,
      selectedSlot: selectedSlot,
      patientDetails: patientDetails,
    });
    setTimeout(() => {
      trackEvent("step-4-selected-patient-type", {
        location: officeId,
        selected_type: eyeExam,
        patient_type: value,
        doctorId: selectedDoctor?.id,
      });
    }, 10);
  };

  const onSelectNextAvail = (date: string) => {
    if (weekCalendar.length > 0 && weekCalendar.indexOf(date) > -1) {
      setSelectedDate(date);
    } else {
      const params = new URLSearchParams(history.location.search);
      params.set("date", date);
      setExecuteOnce(false);
      setSearchDate(date);
      setSelectedDate(date);
      history.replace({ search: params.toString() });
      setTimeslotsExecution();
    }
  };

  const filteredDoctorSlots = (
    condition: (availability: DoctorAvailability) => boolean
  ) => {
    return compact(
      doctorIdsByAvailability.map((doctorId) => {
        const availability = doctorAvailbilities[doctorId];
        const doctor = doctors.find((doctor) => doctor.id === doctorId);
        return (
          doctor &&
          condition(availability) && (
            <ConnectedDoctorSlots
              key={`doctor-cards-${doctor.id}`}
              officeId={officeId}
              doctor={doctor}
              selectedSlot={selectedSlot}
              onSelection={onSelectionTimeSlot}
              onNextMobile={onNextMobile}
              onSelectNextAvail={onSelectNextAvail}
              forDate={selectedDateTemp}
              examType={examType}
              patientReturnStatus={patientReturnStatus}
              office={selectedOffice}
              version={2}
            />
          )
        );
      })
    );
  };

  const isNearByOfficesLoading = useSelector((state: RootState) =>
    selectNearbyOfficeLoadingState(state)
  );

  const doctorsWithAvailability = filteredDoctorSlots(
    (availability) => availability !== null
  );
  const doctorsWithoutAvailability = filteredDoctorSlots(
    (availability) => availability === null
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const isFormValidation = (data: any) => {
    if (
      selectedOffice &&
      data.patientType &&
      data.selectedSlot &&
      data.selectedDoctor &&
      data.eyeExam &&
      patientDetailsValid(data.patientDetails)
    ) {
      setNextButton(false);
      setApptData({
        office: selectedOffice,
        doctor: data.selectedDoctor,
        timeSlot: data.selectedSlot,
        patientType: data.patientType,
        eyeExam: data.eyeExam,
        patientDetails: data.patientDetails,
        lastRequestUrl: searchDate,
      });
    } else {
      if (!nextButton) setNextButton(true);
    }
  };

  const updatePatient = (fieldValue: any) => {
    const pDetails = cloneDeep(patientDetails);
    if (fieldValue?.field === "firstName") {
      pDetails.firstName = fieldValue?.value;
    }
    if (fieldValue?.field === "lastName") {
      pDetails.lastName = fieldValue?.value;
    }
    if (fieldValue?.field === "phoneNumber") {
      pDetails.phoneNumber = fieldValue?.value;
    }
    if (fieldValue?.field === "email") {
      pDetails.email = fieldValue?.value;
    }
    if (fieldValue?.field === "dateOfBirth") {
      pDetails.dateOfBirth = fieldValue?.value;
    }
    if (fieldValue?.field === "smsOptInNotices") {
      pDetails.smsOptInNotices = fieldValue?.value;
    }
    if (fieldValue?.field === "smsOptInPromos") {
      pDetails.smsOptInPromos = fieldValue?.value;
    }
    setPatientDetails(pDetails);
    isFormValidation({
      eyeExam: eyeExam,
      patientType: patientType,
      selectedDoctor: selectedDoctor,
      selectedSlot: selectedSlot,
      patientDetails: pDetails,
    });
  };

  React.useEffect(() => {
    if (!dataPush) {
      if (
        selectedSlot &&
        selectedDoctor &&
        eyeExam &&
        patientType &&
        patientDetailsValid(patientDetails)
      ) {
        setNextButton(false);
        setDataPush(true);
        setApptData({
          office: selectedOffice,
          doctor: selectedDoctor,
          timeSlot: selectedSlot,
          patientType: patientType,
          eyeExam: eyeExam,
          patientDetails: patientDetails,
          lastRequestUrl: searchDate,
        });
      } else {
        setNextButton(true);
        setDataPush(false);
      }
    }
  }, [
    eyeExam,
    selectedOffice,
    patientDetails,
    patientDetailsValid,
    patientType,
    selectedDoctor,
    selectedSlot,
    dispatch,
    searchDate,
    setApptData,
    dataPush,
  ]);

  const moveToNext = () => {
    //isFormValidation(patientDetails);
    isFormValidation({
      eyeExam: eyeExam,
      patientType: patientType,
      selectedDoctor: selectedDoctor,
      selectedSlot: selectedSlot,
      patientDetails: patientDetails,
    });
    if (!nextButton) history.push(paths.v2appointmentReview(officeId));
  };

  React.useEffect(() => {
    setTimeout(() => {
      if (isEditForm) {
        const appData = cloneDeep(appoinmentData);
        // eslint-disable-next-line react-hooks/exhaustive-deps
        selectedOffice = appData["office"];
        setSelectedDoctor(appData["doctor"]);
        setSelectedSlot(appData["timeSlot"]);
        setEyeExam(appData["eyeExam"]);
        setPatientType(appData["patientType"]);
        setPatientDetails(appData["patientDetails"]);
      }
      if (isEditForm && editSection) {
        if (editSection === "timeSlot") {
          setShowSlotSection(false);
          setSelectedDoctor(undefined);
          setSelectedSlot(undefined);
          /*setTimeout(() => {
            WeekTimeSlotsRef?.current?.scrollIntoView();
          }, 10); */
        }
        if (editSection === "patientDetails") {
          setShowSlotSection(true);
          setTimeout(() => {
            patientDetailsRef?.current?.scrollIntoView();
          }, 10);
        }
      }
    }, 10);
  }, [setSelectedDoctor, setSelectedSlot]);

  const today = dayjs().format(API_DATE_FORMAT);
  const restrictDate = 7 * (1 + selectedOffice.timeSlotWeeks);
  const restrictedDay = dayjs(today)
    .add(restrictDate, "day")
    .format(API_DATE_FORMAT);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  let weekCalendar: any[] = [];
  ["0", "1", "2", "3", "4"].map((i) =>
    weekCalendar.push(
      dayjs(searchDate).add(parseInt(i), "day").format(API_DATE_FORMAT)
    )
  );
  const calendarIndex = weekCalendar.indexOf(restrictedDay);
  if (calendarIndex > -1) {
    if (calendarIndex === 0) weekCalendar = [weekCalendar[calendarIndex]];
    else weekCalendar = weekCalendar.slice(0, calendarIndex);
  }
  const trackBrowseDatesEvent = () => {
    setExecuteOnce(false);
    setDateFirstExecuteOnce(false);
    setSelectedDate(undefined);
    setTimeslotsExecution();
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onSelectDate = (date: string) => {
    if (weekCalendar.length > 0 && weekCalendar.indexOf(date) > -1) {
      setSelectedDate(date);
      setExecuteOnce(false);
    } else {
      const params = new URLSearchParams(history.location.search);
      params.set("date", date);
      setExecuteOnce(false);
      setSelectedDate(date);
      history.replace({ search: params.toString() });
    }
  };

  const slotsByDoctor = useSelector((state: RootState) =>
    selectUpcomingTimeslotsForOfficeByDate(state, officeId)
  );

  const isSlotLoaded = useSelector((state: RootState) => selectIsLoaded(state));

  React.useEffect(() => {
    if (isSlotLoaded && !dateFirstexecuteOnce && !isEditForm) {
      const doctorsDayStart = doctors.map((doctor) => {
        return Object.keys(slotsByDoctor[doctor.id])[0];
      });
      let daystart = false;
      for (let i = 0; i < weekCalendar.length; i++) {
        if (daystart) {
          break;
        }
        for (let j = 0; j < doctorsDayStart.length; j++) {
          if (daystart) break;
          if (doctorsDayStart[j] === weekCalendar[i]) {
            daystart = true;
            if (daystart) {
              onSelectDate(weekCalendar[i]);
              setDateFirstExecuteOnce(true);
              //onSetLoadOnce();
              break;
            }
          }
        }
      }
    }
  }, [
    isSlotLoaded,
    isEditForm,
    doctors,
    slotsByDoctor,
    weekCalendar,
    onSelectDate,
    dateFirstexecuteOnce,
  ]);

  React.useEffect(() => {
    if (weekCalendar.length > 0 && !executeOnce) {
      if (
        dayjs(selectedDateTemp).format(API_DATE_FORMAT) ===
        dayjs().format(API_DATE_FORMAT)
      ) {
        setPrevWeek("");
      } else {
        const dateStr = dayjs(weekCalendar[0])
          .subtract(4, "day")
          .format(API_DATE_FORMAT);
        const prevDate = dayjs(weekCalendar[0])
          .subtract(5, "day")
          .format(API_DATE_FORMAT);
        if (!dayjs().isBefore(dateStr)) {
          const params = new URLSearchParams(history.location.search);
          params.set("date", dayjs().format(API_DATE_FORMAT));
          history.replace({ search: params.toString() });
        } else {
          setPrevWeek("start?date=" + prevDate);
        }
      }
      if (
        weekCalendar.length === 5 &&
        weekCalendar[4] !== restrictedDay &&
        !dayjs(restrictedDay).isSame(dayjs(weekCalendar[4]).add(1, "day")) &&
        dayjs(weekCalendar[4]).isBefore(restrictedDay)
      ) {
        setNextWeek(
          "start?date=" +
            dayjs(weekCalendar[4]).add(1, "day").format(API_DATE_FORMAT)
        );
      } else {
        setNextWeek("");
      }
      setExecuteOnce(true);
    }
  }, [
    executeOnce,
    history,
    restrictedDay,
    selectedDateTemp,
    selectedOffice.timeSlotWeeks,
    weekCalendar,
  ]);
  const [checked, setChecked] = React.useState(false);

  const handleChange = () => {
    setChecked(!checked);
  };
  return (
    <div className={styles.mobileView}>
      <Modal
        className={styles.vehModal}
        aria={aria}
        isOpen={vehDoctorTerms}
        closeOnOverlayClick={false}
        hideClose={true}
      >
        <div>
          Because the doctor performing this exam will be remote, you are
          confirming that you meet the following qualifications by checking the
          box below:
        </div>
        <ul>
          {selectedDoctor &&
            selectedDoctor.offersVEH &&
            !selectedOffice.ageRestrictVEH && (
              <li>I am at least 10 years old.</li>
            )}
          {selectedDoctor &&
            selectedDoctor.offersVEH &&
            selectedOffice.ageRestrictVEH && (
              <li>I am between the ages of 18-55.</li>
            )}
          <li>
            I understand that certain patients MAY require additional follow-up
            care for treatment of conditions identified within the visit, and
            that I will follow the doctor’s recommendation if needed{" "}
          </li>
          <li>
            This exam requires a retinal image to help assess the health of the
            eye. The additional fee for retinal imaging is $44 and is generally
            not covered by insurance.
          </li>
        </ul>
        <div>
          <input
            type="checkbox"
            name="teleoptometry"
            id="teleoptometry"
            checked={checked}
            onChange={handleChange}
          />
          I meet all the above requirements. I am ready to schedule my
          video-assisted eye health exam.
        </div>
        <div className={styles.buttons}>
          <Button
            text="Cancel"
            size="large"
            role={ButtonRole.Ghost}
            onClick={() => {
              setVehDoctorTerms(false);
              setSelectedDoctor(undefined);
              setSelectedSlot(undefined);
              setVehSelectedSlot(undefined);
              setShowSlotSection(false);
              setChecked(false);
            }}
            className={styles.button}
          />
          <Button
            text="Next"
            size="large"
            disabled={!checked}
            onClick={() => {
              setVehDoctorTerms(false);
              setShowSlotSection(true);
              setSelectedSlot(vehSelectedSlot);
              setChecked(false);
              dispatch(
                updateSelectedAppointment({
                  officeId,
                  officeUid,
                  ...vehSelectedSlot,
                })
              );
            }}
            className={styles.button}
          />
        </div>
        <div className={styles.vehTerms}>
          *Clicking Cancel will discard your current choice and allow you to
          choose another doctor{" "}
        </div>
      </Modal>
      <div>
        <div className={styles.customSteps}>
          <h1 className={styles.selectOption}>SELECTED option</h1>
        </div>
        <div className={styles.customSteps}>
          <img src={circleCheck} alt="Check" />
          <h1>SELECTED Office</h1>
          <div
            onClick={() => {
              externalRedirect(paths.LOCATIONS_PAGE_SEARCH_URL);
              setShowSlotSection(false);
            }}
            aria-hidden="true"
          >
            Change
          </div>
        </div>
        <div
          className={
            !showSlotSection ? styles.hideTimeSection : styles.showTimeSection
          }
        >
          <div className={styles.customSteps}>
            <img src={circleCheck} alt="Check" />
            <h1>SELECTED DOCTOR AND TIME</h1>
            <div onClick={() => setShowSlotSection(false)} aria-hidden="true">
              Change
            </div>
          </div>
        </div>
      </div>
      <div
        className={
          showSlotSection ? styles.hideTimeSection : styles.showTimeSection
        }
      >
        <NearbyOffice
          nearbyOffices={nearbyOffices}
          patientReturnStatus={patientReturnStatus}
          examType={examType}
          loading={isNearByOfficesLoading}
          version={2}
          isMobile={true}
        />
        <div className={styles.dateContainer}>
          <h1 className={styles.headline}>Select doctor and time slot</h1>
          <div className={styles.weekContainer}>
            <div className={cx(styles.arrow, styles.arrowLeft)}>
              <NavigationButton
                url={prevWeek}
                disabled={prevWeek === ""}
                direction="left"
                onClick={trackBrowseDatesEvent}
              />
            </div>
            {weekCalendar.map((day) => (
              <div
                className={
                  selectedDateTemp === day
                    ? cx(styles.dayContainer, styles.daySelected)
                    : styles.dayContainer
                }
                onClick={() => onSelectDate(day)}
                aria-hidden="true"
              >
                <div className={styles.dayWords}>
                  {dayjs(day).format("ddd")}
                </div>
                <div className={styles.monthYear}>
                  {dayjs(day).format("MMM")} {dayjs(day).format("D")}
                </div>
              </div>
            ))}
            <div className={cx(styles.arrow, styles.arrowRight)}>
              <NavigationButton
                url={nextWeek}
                disabled={nextWeek === ""}
                direction="right"
                onClick={trackBrowseDatesEvent}
              />
            </div>
          </div>
        </div>
      </div>
      <div className={styles.officeSection}>
        <div
          className={
            showSlotSection ? styles.hideTimeSection : styles.showTimeSection
          }
        >
          {doctors.length > 0 ? (
            doctorsWithAvailability
          ) : (
            <div className={styles.noAvailableDoctors}>
              Sorry there are no available doctors at this office
            </div>
          )}
          <div className={styles.borderDivider}></div>
          <WaitlistV2 office={selectedOffice} />
          <div className={styles.borderDivider}></div>
          {doctorsWithoutAvailability.length > 0 && (
            <DoctorAccordion title="Doctors with limited availability">
              {doctorsWithoutAvailability}
            </DoctorAccordion>
          )}
        </div>
        <div
          className={
            showSlotSection ? styles.showTimeSection : styles.hideTimeSection
          }
        >
          <div ref={examTypeRef}>
            <AppointmentExamDetails
              office={selectedOffice}
              eyeExam={eyeExam}
              onSelection={onSelectionExamType}
              isMobileView={isMobileView}
            />
          </div>
          <div className={styles.borderDividerSection}></div>
          <div ref={patientTypeRef}>
            <AppointmentSelectReturnStatus
              patientType={patientType}
              onSelection={onSelectionPatientType}
            />
          </div>
          <div className={styles.borderDividerSection}></div>
          <div ref={patientDetailsRef}>
            <AppointmentAccountSetup
              office={selectedOffice}
              doctor={selectedDoctor}
              patient={patientDetails}
              updatePatient={updatePatient}
              isMobileView={isMobileView}
            />
          </div>
        </div>
        <div
          className={cx(
            styles.buttonWrapper,
            !showSlotSection ? styles.hideTimeSection : styles.showTimeSection
          )}
        >
          <div className={styles.container}>
            <div
              className={cx(
                [styles.tooltipContainer, styles.tooltipPositionTop],
                {
                  [styles.tooltipVisible]: nextButton,
                }
              )}
            >
              <div className={styles.contentContainer}>
                <div className={styles.content}>
                  {selectedDoctor && (
                    <div className={styles.classWhite}>
                      <img
                        className={styles.iconCircle}
                        src={successCircle}
                        alt="success"
                      />{" "}
                      Time Slot selected{" "}
                    </div>
                  )}
                  {!selectedDoctor && (
                    <div className={styles.classRed}>
                      <img
                        className={styles.iconCircle}
                        src={errorCircle}
                        alt="Error"
                      />{" "}
                      Time Slot not selected
                    </div>
                  )}
                  {eyeExam && (
                    <div className={styles.classWhite}>
                      <img
                        className={styles.iconCircle}
                        src={successCircle}
                        alt="success"
                      />{" "}
                      Eye Exam selected{" "}
                    </div>
                  )}
                  {!eyeExam && (
                    <div className={styles.classRed}>
                      <img
                        className={styles.iconCircle}
                        src={errorCircle}
                        alt="Error"
                      />{" "}
                      Eye Exam not selected
                    </div>
                  )}
                  {patientType && (
                    <div className={styles.classWhite}>
                      <img
                        className={styles.iconCircle}
                        src={successCircle}
                        alt="success"
                      />{" "}
                      Patient Type selected{" "}
                    </div>
                  )}
                  {!patientType && (
                    <div className={styles.classRed}>
                      <img
                        className={styles.iconCircle}
                        src={errorCircle}
                        alt="Error"
                      />{" "}
                      Patient Type not selected
                    </div>
                  )}
                  {patientDetailsValid(patientDetails) && (
                    <div className={styles.classWhite}>
                      <img
                        className={styles.iconCircle}
                        src={successCircle}
                        alt="success"
                      />{" "}
                      Patient Details updated
                    </div>
                  )}
                  {!patientDetailsValid(patientDetails) && (
                    <div className={styles.classRed}>
                      <img
                        className={styles.iconCircle}
                        src={errorCircle}
                        alt="Error"
                      />{" "}
                      Patient Details not updated
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div>
              <Button
                className={styles.footerNextBtn}
                text="REVIEW DETAILS"
                onClick={moveToNext}
                role={ButtonRole.Standard}
                size={"large"}
                disabled={nextButton}
              />
            </div>
          </div>
        </div>
        <div className={styles.footer}>
          <section className={cx(styles.footerSection, styles.footerLeft)}>
            <div className={styles.footerHeadingWithTooltip}>
              <h3 className={styles.footerHeading}>
                Other eyecare/vision needs?
              </h3>
              <InfoTooltip>
                <p>
                  Please call our office to schedule appointments for follow-ups
                  or urgent concerns.
                </p>
                <p>
                  Urgent concerns may include any kind of eye infection or
                  injury that impairs vision. Some examples are seeing floaters
                  or flashing lights.
                </p>
              </InfoTooltip>
            </div>
            <p className={styles.footerParagraph}>
              If you are experiencing any eyecare/vision needs outside of your
              exam, please call the office at{" "}
              <a
                href={toTel(formattedPhone)}
                className={styles.footerPhoneLink}
              >
                {formattedPhone}
              </a>{" "}
              to schedule an&nbsp;appointment.
            </p>
          </section>
          <hr className={styles.footerDivider} />
          <section className={cx(styles.footerSection, styles.footerRight)}>
            <h3 className={styles.footerHeading}>
              Need new glasses or contacts?
            </h3>
            <p className={styles.footerLinkContainer}>
              <a
                href={`${MYEYEDR_WEBSITE_URL}/eye-wear/shop-glasses`}
                target="_blank"
                rel="noreferrer"
                className={styles.footerLink}
              >
                Browse Glasses
              </a>
            </p>
            <p className={styles.footerLinkContainer}>
              <a
                href={orderContactUrl}
                target="_blank"
                rel="noreferrer"
                className={styles.footerLink}
              >
                Order Contacts
              </a>
            </p>
          </section>
        </div>
        <div className={styles.footerPortal}>
          <FooterPortal />
        </div>
      </div>
    </div>
  );
};
