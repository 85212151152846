import React from "react";
import dayjs from "dayjs";
import styles from "./Footer.module.scss";

export const FooterPortal: React.FC = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.leftContainer}>
        <div className={styles.copyRight}>
          &#169; {dayjs().year()} MyEyeDr. All rights reserved.
        </div>
      </div>
      <div className={styles.rightContainer}>
        <ul>
          <li>
            <a
              href="https://www.myeyedr.com/privacy-policy-practices"
              target="_blank"
              rel="noreferrer"
            >
              Privacy Policy & Practices
            </a>
          </li>
          <li>
            <a
              href="https://www.myeyedr.com/terms-use"
              target="_blank"
              rel="noreferrer"
            >
              Terms of Use
            </a>
          </li>
        </ul>
      </div>
    </footer>
  );
};
